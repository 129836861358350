export default {
  name: "WorkSafe",
  heading1: "Your Safety,",
  heading2: "In your control",
  description: "Multi-sensory AI to build a safer and smarter world",
  keyWords: [
    "health",
    "productivity",
    "remote monitoring",
    "manufacturing",
    "IMDA",
    "vulcan ai",
    "vulcan ai singapore",
    "worksafe",
    "vulcan worksafe",
    "vulcan worksmart",
    "worksmart",
    "facility management",
    "construction safety",
    "maritime safety",
    "ships safety",
    "workplace safety and health",
    "singapore smart nation",
    "ai singapore",
    "mom",
    "innovation",
    "safety wearable",
    "wearable technology",
    "stf",
    "slips trips and falls",
    "fall detection watch",
    "near miss",
    "wsh incident reporting",
    "fall",
    "ppe",
    "smart building",
    "IoT",
    "Industrial AI",
    "Industry 4.0",
    "Enterprise AI",
    "Democratize AI",
    "SMART Factory",
    "Computer Vision",
    "SMART Wearables",
    "heart rate",
    "fatigue",
    "exertion",
    "alert",
    "remote health",
    "monitoring",
    "gps tracking",
    "smartwatch",
    "worker safety",
    "productivity"
  ],
}
