import React, { useRef, useState } from "react"
import axios from "axios"
import Layout from "../components/layout"
import SEO from "../components/seo"
import page from "../data/workSafeData"
import workSafeLogo from "../images/worksafe-stf/worksafe-stf-logo.png"
import workSafeDashboard from "../images/worksafe-stf/worksafe-stf-dashboard.png"
import chyeThiamLogo from "../images/worksafe-stf/chyethiamlogo.png"
import samwohLogo from "../images/worksafe-stf/samwoh.png"
import momLogo from "../images/worksafe-stf/momLogo.png"
import wshiLogo from "../images/worksafe-stf/wshilogo.png"
import bcaLogo from "../images/worksafe-stf/bcalogo.png"
import imdaLogo from "../images/worksafe-stf/imdalogo.png"
import mpaLogo from "../images/worksafe-stf/mpalogo.png"
import newsNUS from "../images/worksafe-stf/news-nus.png"
// import singtelLogo from "../images/worksafe-stf/singtellogo.png"
// import cdlLogo from "../images/worksafe-stf/cdllogo.png"
// import satsLogo from "../images/worksafe-stf/satslogo.png"
// import keppelfelsLogo from "../images/worksafe-stf/keppelfelslogo.png"
import shimizuLogo from "../images/worksafe-stf/shimizu.png"
import ls2Logo from "../images/worksafe-stf/ls2-logo.png"
// import sifmaLogo from "../images/worksafe-stf/sifmalogo.png"
// import batuLogo from "../images/worksafe-stf/batulogo.png"
import awardIcon from "../images/worksafe-stf/award.png"
import spcBadgeIcon from "../images/worksafe-stf/spc-badge.png"
import alertIcon from "../images/worksafe-stf/alert.png"
import detectIcon from "../images/worksafe-stf/detect.png"
import senseIcon from "../images/worksafe-stf/sense.png"
import reportIcon from "../images/worksafe-stf/report.png"
import cnaSnip from "../images/worksafe-stf/cna-news.png"
import channel5Snip from "../images/worksafe-stf/channel-5.png"
import channel8Snip from "../images/worksafe-stf/channel-8.png"
import mpMelvinSnip from "../images/worksafe-stf/MP-Melvin.png"
import AnimatedLink from "../components/AnimatedLink"
import { Col, Row, Alert } from "react-bootstrap"
const pioneers = [
  {
    img: shimizuLogo,
    alt: "Shimizu Corporation",
    width: "99%",
    description:
      "One of the top 5 general contractors in Japan and among the top 20 in the world.",
  },
  {
    img: ls2Logo,
    alt: "LS 2",
    description: "One of Singapore’s leading Cleaning and Conservancy Company",
  },
  {
    img: chyeThiamLogo,
    alt: "Chye Thiam",
    width: "30%",
    description:
      "One of Singapore biggest environment and building maintenance companies",
  },
  {
    img: samwohLogo,
    alt: "Samwoh",
    width: "50%",
    description: "Singapore’s leading integrated construction company",
  },
]
const collaborators = [
  {
    img: momLogo,
    alt: "Ministry of Manpower",
  },
  {
    img: wshiLogo,
    alt: "WSH Institute",
  },
  {
    img: imdaLogo,
    alt: "Infocomm Media Development Authority",
  },
  {
    img: bcaLogo,
    alt: "Building and Construction Authority",
    width: "50%",
  },
]

const features = [
  {
    name: "Sense",
    icon: senseIcon,
    description: "Multi-Sensory hybrid approach via video and wearable sensors",
  },
  {
    name: "Detect",
    icon: detectIcon,
    description:
      "Patented Artificial Intelligence to detect workplace accidents and hazards",
  },
  {
    name: "Alert",
    icon: alertIcon,
    description:
      "Send real-time alerts to safety officers for immediate assistance",
  },
  {
    name: "Report",
    icon: reportIcon,
    description:
      "Report and analyse Slip-Trip-Fall incidents over time through WorkSafe Analytics",
  },
]

const mediaMentions = [
  {
    url:
      "https://www.channelnewsasia.com/news/singapore/new-ai-system-seeks-to-prevent-slips-and-falls-at-workplaces-14465912",
    image: cnaSnip,
    title: "Channel News Asia Singapore Tonight",
    date: "22 March 2021",
  },
  {
    url: "https://www.mewatch.sg/watch/Mar-2021-CH-5-News-Tonight-205984",
    image: channel5Snip,
    title: "Channel 5 Singapore Tonight",
    date: "22 March 2021",
  },
  {
    url:
      "https://www.8world.com/vodcasts/episode/full/frontline-workplace-safety-1417446",
    image: channel8Snip,
    title: "Channel 8 Frontline",
    date: "12 March 2021",
  },
  {
    url:
      "https://news.nus.edu.sg/pier71-launches-smart-port-challenge-2021-and-announces-seven-start-up-grant-recipients/",
    image: newsNUS,
    title: "PIER71 announces seven start-up grant recipients",
    date: "18 June 2021",
  },
  {
    url: "https://www.facebook.com/melvinyong.sg/posts/2579127752390638",
    image: mpMelvinSnip,
    title: "Using artificial intelligence to enhance workplace safety",
    date: "26 March 2021",
  },
  {
    url:
      "https://www.mom.gov.sg/-/media/mom/documents/press-releases/2021/0322-annex-c---stf-technology-solution.pdf",
    title: "WSHI, MOM - Factsheet on Slip, Trip, Fall Technology Solution",
    image: momLogo,
    date: "19 March 2021",
  },
  {
    url:
      "https://www.mom.gov.sg/newsroom/press-releases/2021/0319-wsh-report-2020",
    title: "MOM Press Release",
    image: momLogo,
    date: "19 March 2021",
  },
  {
    url:
      "https://www.mpa.gov.sg/web/portal/home/media-centre/news-releases/detail/f01a5f4c-b85a-4018-bd66-37455c2fd755",
    image: mpaLogo,
    title:
      "Winners of Smart Port Challenge 2020 bring Tech Innovation to Maritime",
    date: "17 November 2020",
  },
]
const awards = [
  {
    title: "WINNERS",
    challenge: "Smart Port Challenge 2020",
    organiser_1: "Maritime and Port Authority of Singapore",
    organiser_2: "National University of Singapore Enterprise",
    icon: spcBadgeIcon,
  },

  {
    title: "WINNERS",
    challenge: "Open Innovation Challenge 2020",
    organiser_1: "Infocomm Media Development Authority Singapore",
    organiser_2: "Ministry of Manpower Workplace Safety and Health Institute",
    icon: awardIcon,
  },
]
const WorkSafe = () => {
  let featuresRef = useRef()
  let downloadBrochureRef = useRef()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [count, setCount] = useState("")
  const [company, setCompany] = useState("")
  const [submittingFlag, setSubmittingFlag] = useState(false)
  const [phone, setPhone] = useState("")
  const [sector, setSector] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const scrollToRef = ref => {
    if (ref) window.scrollTo(0, ref.current.offsetTop - 100)
  }
  const handleSubmit = evt => {
    evt.preventDefault()
    // Axioos
    setSubmittingFlag(true)

    axios
      .post(
        "https://vulcanwebcontact.azurewebsites.net/api/worksafe-download-brochure?code=5xRCFn/76j4KIjjFgBWYe0XVwWxEcbApQKNy0153OTYctHOoO1/xTw==",
        { name, email, phone, count, company, sector }
      )
      .then(res => {
        if (res.data && res.data.links) {
          res.data.links.forEach(x => window.open(x.url))
        }
        setSubmittingFlag(false)
        setShowSuccess(true)
        setShowError(false)
      })
      .catch(err => {
        setSubmittingFlag(false)
        setShowError(true)
        setShowSuccess(false)
      })
  }
  return (
    <Layout
      pageInfo={{ pageName: page.name }}
      hideTimeline={true}
      className="bg-worksafe"
    >
      <SEO
        title={page.name}
        description={
          "Vulcan WorkSafe keeps your workplace safe by using Wearable & Vision AI Technology to provide real-time detection and alerts of any safety related incidents."
        }
        keywords={page.keyWords}
      />
      <div id="worksafe-page-content">
        <section className="container my-5">
          <div className="row" id="worksafe-header">
            <div className="col-md-6 col-sm-12">
              <div className="intro">
                <div className="title">
                  <div className="logo">
                    <img src={workSafeLogo} alt="WorkSafe" />
                  </div>
                  <div className="name">
                    <span>Work</span>
                    <span className="blue">Safe</span>
                  </div>
                </div>
                <div className="heading">
                  {page.heading1}
                  <br></br>
                  {page.heading2}
                </div>
                <div className="desc">{page.description}</div>
                <div className="cta">
                  <button
                    className="find-out-more"
                    onClick={() => scrollToRef(downloadBrochureRef)}
                  >
                    FIND OUT MORE
                  </button>
                  <AnimatedLink
                    to="/contact#contact-form"
                    className="link-no-style"
                  >
                    <button className="contact-us">CONTACT US</button>
                  </AnimatedLink>
                </div>
                {/* <div className="brochure-link">
                  <button onClick={() => scrollToRef(downloadBrochureRef)}>
                    DOWNLOAD BROCHURE
                    <img src={rightArrow} alt="Download Brochure" />
                  </button>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="worksafe-dashboard">
                <img src={workSafeDashboard} alt="WorkSafe Dashboard" />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-worksafe-gray py-5">
          <div className="container" ref={featuresRef}>
            <div id="worksafe-features">
              <h3 className="text-center spartan-font mb-4">Features</h3>
              <div className="features">
                {features.map((feature, index) => (
                  <div key={index} className={`feature my-4  `}>
                    <div className="icon text-center">
                      <img src={feature.icon} alt={feature.name} />
                    </div>
                    <div className="info">
                      <div className="name">{feature.name}</div>
                      <div className="description">{feature.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container ">
            <div id="worksafe-awards">
              <h3 className="text-center spartan-font mb-4">
                Award Winning Workplace Safety and Health Tech (WSHTech)
              </h3>
              <div className="awards">
                {awards.map((award, index) => (
                  <div className="award mx-4  text-center" key={index}>
                    <img
                      src={award.icon}
                      alt={award.challenge}
                      className="mb-3"
                      style={{ height: "150px" }}
                    />
                    <div className="title">{award.title}</div>
                    <div className="challenge">{award.challenge}</div>
                    <div className="organiser-1">{award.organiser_1}</div>
                    <div className="organiser-2">{award.organiser_2}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 bg-worksafe-gray">
          <div className="container ">
            <div id="worksafe-media-releases">
              <h3 className="text-center spartan-font mb-4">Media Releases</h3>
              <div className="media-mentions">
                {mediaMentions.map((media, index) => (
                  <button
                    key={index}
                    className="media-mention"
                    onClick={() => window.open(media.url)}
                  >
                    <img
                      className="d-none d-lg-block"
                      src={media.image}
                      alt={media.title}
                    />
                    <div className="info bg-worksafe-gray ">
                      <div className="title">{media.title}</div>
                      <div className="date">{media.date}</div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 ">
          <div className="container">
            <div id="worksafe-collaborations">
              <h3 className="text-center spartan-font ">WorkSafe Pioneers</h3>
              <p className="text-center">
                Leading Singapore Companies that have put Safety First for their
                staff by signing up to be early adopters of Vulcan WorkSafe AI
                Solutions
              </p>
              <div className="pioneers">
                {pioneers.map((coll, index) => (
                  <div className="pioneer" key={index}>
                    <img
                      src={coll.img}
                      alt={coll.alt}
                      style={{ width: coll.width ? coll.width : "80%" }}
                      title={coll.alt}
                    />
                    {coll.description ? (
                      <div className="description text-center">
                        {coll.description}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <h3 className="text-center spartan-font pt-5 ">
                WorkSafe Collaborators
              </h3>

              <div className="collaborators ">
                {collaborators.map((coll, index) => (
                  <div className="collaborator" key={index}>
                    <img
                      src={coll.img}
                      alt={coll.alt}
                      style={{ width: coll.width ? coll.width : "80%" }}
                      title={coll.alt}
                    />
                    {coll.description ? (
                      <div className="description text-center">
                        {coll.description}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="bg-worksafe-gray py-5">
          <div className="container">
            <div id="worksafe-brochure" ref={downloadBrochureRef}>
              <h3 className="text-center spartan-font mb-4">
                Download Brochure
              </h3>
              <form action="" id="contactForm" onSubmit={handleSubmit}>
                <Row>
                  <Col sm="4">
                    <div className="form-group  pb-2">
                      <label htmlFor="name">
                        Name<span className="required-aestrisk">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="form-group  pb-2">
                      <label htmlFor="comapny">
                        Company<span className="required-aestrisk">*</span>
                      </label>
                      <input
                        type="text"
                        name="comapny"
                        id="comapny"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="form-group  pb-2">
                      <label htmlFor="email">
                        Work Email <span className="required-aestrisk">*</span>
                      </label>

                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className="form-control"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    <div className="form-group  pb-2">
                      <label htmlFor="tel">Phone</label>

                      <input
                        type="tel"
                        name="tel"
                        id="tel"
                        className="form-control"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        pattern="+[0-9]{2}[0-9]{10}"
                      />
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="form-group  pb-2">
                      <label htmlFor="comapny">Sector</label>
                      <input
                        type="text"
                        name="sector"
                        id="sector"
                        value={sector}
                        onChange={e => setSector(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="form-group  pb-2">
                      <label htmlFor="comapny">Employee count</label>
                      <input
                        type="number"
                        name="employeeCount"
                        id="employeeCount"
                        value={count}
                        onChange={e => setCount(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  {!submittingFlag ? (
                    <input type="submit" value="DOWNLOAD" />
                  ) : (
                    <button type="button">
                      SUBMITTING
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  )}
                </div>
              </form>
              <Alert
                show={showSuccess}
                variant="success"
                className="mt-4 text-center"
              >
                <h5 className="m-auto">
                  Thanks for your interest in Workplace Safety AI solutions from
                  Vulcan AI. We will be in touch shortly.
                </h5>
              </Alert>
              <Alert
                show={showError}
                variant="danger"
                className="mt-4 text-center"
              >
                <h4 className="m-auto">{"Error occured"}</h4>
              </Alert>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default WorkSafe
